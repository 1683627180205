export class ProcessSignUp {
  static readonly type = '[User-Auth] ProcessSignUp';
  constructor(public payload) { }
}

export class ConfirmPatientCheckIn {
  static readonly type = '[Patient-Check-In-Confirm] ConfirmPatientCheckIn';
  constructor(public payload) { }
}

export class PatientCheckIn {
  static readonly type = '[Patient-Check-In] PatientCheckIn';
  constructor(public payload) { }
}

export class ClearPatientCheckIn {
  static readonly type = '[Patient-Check-In] ClearPatientCheckIn';
  constructor() { }
}

export class ClearconfirmCheckIn {
  static readonly type = '[Patient-Check-In-Confirm] ClearconfirmCheckIn';
  constructor() { }
}

export class ProcessLogin {
  static readonly type = '[User-Auth] ProcessLogin';
  constructor(public payload) { }
}

export class AccountUnLockUsingOTP {
  static readonly type = '[User-Auth] AccountUnLockUsingOTP';
  constructor(public payload) { }
}
export class ClearLoginDetals {
  static readonly type = '[User-Auth] ClearLoginDetals';
  constructor() { }
}
export class ClearForgotPaswdResponse {
  static readonly type = '[User-Auth] ClearForgotPaswdResponse';
  constructor() { }
}
export class ClearKeycloackSignUpResponse {
  static readonly type = '[User-Auth] ClearKeycloackSignUpResponse';
  constructor() { }
}
export class ClearSignupResponse {
  static readonly type = '[User-Auth] ClearSignupResponse';
  constructor() { }
}
export class ResendVerification {
  static readonly type = '[User-Auth] ResendVerification';
  constructor(public payload) { }
}

export class FetchPatientInvoiceList {
  static readonly type = '[Billing] Fetch Patient Invoice All';
  constructor(public payload) { }
}
export class PayInvoiceByCash {
  static readonly type = '[Billing] Pay by Cash';
  constructor(public payload) { }
}
export class PayInvoiceByCard {
  static readonly type = '[Billing] Pay by Card';
  constructor(public payload) { }
}

export class ClearInvoiceList {
  static readonly type = '[Billing] Clear Patient Invoice All';
}

export class GetBillingPaymentResponse {
  static readonly type = '[Billing] Get Patient Response';
  constructor(public payload) { }
}

export class Forgotpassword {
  static readonly type = '[User-Auth] Forgotpassword';
  constructor(public payload) { }
}
export class IntitiateOscarCall {
  static readonly type = '[oscar call ] Fetch';
  constructor(public payload: any) { }
}
export class FetchToken {
  static readonly type = '[Token] Fetch';
}

export class FetchUserInfo {
  static readonly type = '[Logged In User Info] Fetch';
  constructor(public payload: any) { }
}

export class UpdateToken {
  static readonly type = '[Token] Update';
  constructor(public payload: any) { }
}
export class ValidatePHN {
  static readonly type = '[PHN] Validate';
  constructor(public payload: any) { }
}
export class KeycloackSignUp {
  static readonly type = '[User-Auth] KeycloackSignUp';
  constructor(public payload) { }
}
export class GetKeycloakUserDetails {
  static readonly type = '[User-Auth] GetKeycloakUserDetails';
  constructor(public payload) { }
}
export class ClearKeycloackUserResponse {
  static readonly type = '[User-Auth] ClearKeycloackUserResponse';
  constructor() { }
}

export class FetchPendingInvoiceList {
  static readonly type = '[Billing] Fetch Pending Invoices';
  constructor(public payload) { }
}

export class BlockedAccountsList {
  static readonly type = '[Account-Auth] BlockedAccountsList';
  constructor() { }
}

export class ExecuteUnBlockAccount {
  static readonly type = '[Account-Auth] ExecuteUnBlockAccount';
  constructor(public payload) { }
}

export class FetchProviderToken {
  static readonly type = '[Provider] Fetch token';
  constructor(public payload) { }
}

export class ClearProviderToken {
  static readonly type = '[User-Auth] ClearProviderToken';
  constructor() { }
}
export class ResetPassword {
  static readonly type = '[User-Auth] ResetPassword';
  constructor(public payload) { }
}
export class ClearResetResponse {
  static readonly type = '[User-Auth] ClearResetResponse';
  constructor() { }
}